import { render, staticRenderFns } from "./ViewRequestsBody.vue?vue&type=template&id=726eb35e&scoped=true&"
import script from "./ViewRequestsBody.vue?vue&type=script&lang=js&"
export * from "./ViewRequestsBody.vue?vue&type=script&lang=js&"
import style0 from "./ViewRequestsBody.vue?vue&type=style&index=0&id=726eb35e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "726eb35e",
  null
  
)

export default component.exports